// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-templates-directory-tsx": () => import("./../../../src/templates/directory.tsx" /* webpackChunkName: "component---src-templates-directory-tsx" */),
  "component---src-templates-html-tsx": () => import("./../../../src/templates/html.tsx" /* webpackChunkName: "component---src-templates-html-tsx" */),
  "component---src-templates-markdown-tsx": () => import("./../../../src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */)
}

